import Image from 'next/image';
import { Box, Container, Link as MuiLink, SxProps, Typography } from '@mui/material';
import logoAssociationsA3 from './assets/logoAssociationsA3.svg';
import logoAssociationsAhtd from './assets/logoAssociationsAhtd.png';
import logoAssociationsIsa from './assets/logoAssociationsIsa.svg';
type AssociationsBannerProps = {
  overrideTitle?: React.ReactNode;
  containerSx?: SxProps;
  titleSx?: SxProps;
  logosHeight?: number;
};
export const AssociationsBanner = ({
  overrideTitle,
  containerSx,
  titleSx,
  logosHeight = 66
}: AssociationsBannerProps) => <Container maxWidth="md" sx={{
  py: 6,
  textAlign: 'center',
  ...containerSx
}} data-sentry-element="Container" data-sentry-component="AssociationsBanner" data-sentry-source-file="AssociationsBanner.tsx">
    {overrideTitle ?? <Typography component="h2" sx={{
    textAlign: 'center',
    typography: {
      xs: 'h5',
      md: 'h4'
    },
    ...titleSx
  }}>
        Quotebeam is a proud member of:
      </Typography>}
    <Box sx={{
    mt: 4,
    display: 'flex',
    flexDirection: {
      xs: 'column',
      md: 'row'
    },
    gap: 4,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%'
  }} data-sentry-element="Box" data-sentry-source-file="AssociationsBanner.tsx">
      <MuiLink href="https://www.ahtd.org" target="_blank" rel="nofollow noopener noreferrer" title="Association for High Technology Distribution" sx={{
      position: 'relative',
      width: 278,
      height: logosHeight
    }} data-sentry-element="MuiLink" data-sentry-source-file="AssociationsBanner.tsx">
        <Image alt="Association for High Technology Distribution Logo" src={logoAssociationsAhtd} unoptimized fill style={{
        objectFit: 'contain'
      }} data-sentry-element="Image" data-sentry-source-file="AssociationsBanner.tsx" />
      </MuiLink>
      <MuiLink href="https://www.automate.org" target="_blank" rel="nofollow noopener noreferrer" title="Association for Advancing Automation" sx={{
      position: 'relative',
      width: 309,
      height: logosHeight
    }} data-sentry-element="MuiLink" data-sentry-source-file="AssociationsBanner.tsx">
        <Image alt="Association for Advancing Automation Logo" src={logoAssociationsA3} fill unoptimized style={{
        objectFit: 'contain'
      }} data-sentry-element="Image" data-sentry-source-file="AssociationsBanner.tsx" />
      </MuiLink>
      <MuiLink href="https://www.isa.org/" target="_blank" rel="nofollow noopener noreferrer" title="The International Society of Automation" sx={{
      position: 'relative',
      width: 249,
      height: logosHeight,
      justifyContent: 'flex-start'
    }} data-sentry-element="MuiLink" data-sentry-source-file="AssociationsBanner.tsx">
        <Image alt="The International Society of Automation Logo" src={logoAssociationsIsa} fill unoptimized data-sentry-element="Image" data-sentry-source-file="AssociationsBanner.tsx" />
      </MuiLink>
    </Box>
  </Container>;